<template>
	<div id="page">
		<div id="section_wrapper" class="fullwidth horizontal">
			<section ref="contact-top">
				<div class="section_content co_sc fl-c">
					<div class="co_q fl-co-c">
						<span class="upper co_st">Any large-scale project?</span>
						<h1 class="co_h">Hit me up</h1>
						<div class="co_links fl-co-c">
							<div href="https://www.linkedin.com/in/pordeli/" class="w_link upper fl-c link_ul"
								@mousemove="updateCursorEl([$event,{x:false}])"
								@mouseleave="updateCursorEl"
								@click="updateQuery({name: 'contact_form', animate: true})">
								<div class="fl-c pe">
									<span>Leave me a message</span>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.8 23.8" v-bind:qsvg="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M22.8 11.8L16 5.1v5.7H1v2h15v5.8z"/></svg>
								</div>
							</div>
							<a href="https://www.linkedin.com/in/pordeli/" target="_blank" class="w_link upper fl-c link_ul"
								@mousemove="updateCursorEl([$event,{x:false}])"
								@mouseleave="updateCursorEl">
								<div class="fl-c pe">
									<span>Linkedin</span>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.7 14.7" v-bind:qsvg="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M21.7 0h-9.6l4.1 4.1-8.6 8.6H0v2h8.4l9.2-9.2 4.1 4z"/></svg>
								</div>
							</a>
						</div>
					</div>
				</div>
			</section>
			<contact-form ref="contact_form"></contact-form>
		</div>
	</div>
</template>

<script>
	import { mapActions } from 'vuex'
	import ContactForm from '@/components/contact/ContactForm.vue'

export default {
	name: 'Contact',
	components: {
		ContactForm
	},
	mounted() {
		this.setSections([this.$refs, this.$route.name])
	},
	methods: {
		...mapActions("GeneralStore", ['setSections','updateCursorEl','updateQuery']),
	},
}
</script>