<template>
    <section>
        <div class="section_content fl-c h1">

            <form class="form" @submit.prevent="onSubmit" :class="{posting}">
                <div class="f_section">
                    <div class="f_item" :class="{ error: error.name }" ref="name">
                        <input class="f_in"
                            @focus="inputFucus"
                            @blur="inputFucus($event, false)"
                            @mouseenter="updateCursor('input')"
                            @mouseleave="updateCursor(false)"
                            type="text"
                            v-model="name">
                        <div class="f_label pe fl-sb-c upper">
                            {{ label.name }}
                        </div>
                        <div class="f_error">
                            <span>{{ errorText.name }}</span>
                        </div>
                    </div>
                    <div class="f_item" :class="{ error: error.email }" ref="email">
                        <input class="f_in"
                            @focus="inputFucus"
                            @blur="inputFucus($event, false)"
                            @mouseenter="updateCursor('input')"
                            @mouseleave="updateCursor(false)"
                            type="text"
                            v-model="email">
                        <div class="f_label pe fl-sb-c upper">
                            {{ label.email }}
                        </div>
                        <div class="f_error">
                            <span>{{ errorText.email }}</span>
                        </div>
                    </div>
                </div>
                <div class="f_section">
                    <div class="f_item f_ta" :class="{ error: error.msg }" ref="msg">
                        <textarea class="f_in"
                            @focus="inputFucus"
                            @blur="inputFucus($event, false)"
                            @mouseenter="updateCursor('input')"
                            @mouseleave="updateCursor(false)"
                            v-model="msg"></textarea>
                        <div class="f_label pe fl-sb-c upper">
                            {{ label.msg }}
                        </div>
                        <div class="f_error">
                            <span>{{ errorText.msg }}</span>
                        </div>
                    </div>
                </div>
                <vue-recaptcha
                    ref="invisibleRecaptcha"
                    @verify="onVerify"
                    size="invisible"
                    :sitekey="recaptchaKey"
                    :loadRecaptchaScript= "true">
                </vue-recaptcha>
                <button type="submit" class="f_submit upper w_link link_ul fl-c"
                    :class="{ disabled: hasError }"
                    @mousemove="updateCursorEl([$event,{x:false}])"
                    @mouseleave="updateCursorEl">
                        <span class="pe">Send</span>
                </button>
                <div class="f_ge" :class="{ error: hasError }">
                    <div class="f_error">
                        <span>{{ errorText.general }}</span>
                    </div>
                </div>
                <div class="f_alert fl-c ab b-c upper"
                    v-show="showAlert"
                    :class="{ success: alert.success }">
                    <span v-html="alert.txt"></span>
                </div>
            </form>
        </div>
    </section>
</template>

<script>

import VueRecaptcha from 'vue-recaptcha';
import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'




export default {
    name: 'ContactForm',
    components: {
        VueRecaptcha
    },
	data: () => ({
        name    : '',
        email   : '',
        msg     : '',

        error: {
            name    : false,
            email   : false,
            msg     : false
        },

        showAlert   : false,
        alert: {
            txt       : '',
            success   : false
        },

        postingTimeout: null,
        posting   : false,
        submitted : false,
        recaptchaKey: '6LeaJt8UAAAAAHMxNFeP-5NdJgO8Ex-tH5qJHLUB', // Invisible
    }),
    computed: {
        ...mapGetters("PagesStore", ['getPageInfo']),
        allInputs() { return [this.name, this.email, this.msg].join() },
        hasError() { return Object.values(this.error).includes(true) },
        contact() { return this.getPageInfo('contact')},
        label() { return this.contact.label },
        errorText() { return this.contact.errorText },
    },
	methods: {
        ...mapActions("GeneralStore",['updateCursorEl','updateCursor']),
        inputFucus(e, over=true) {
            let el = e.target.parentElement
            let val = e.target.value

            if(over || val) {
                 el.setAttribute("focused", "")
            } else {
                el.removeAttribute("focused")
            }
        },
        async post() {
            // let url     = 'http://hoborg:81/email/'
            let url     = 'https://hoborg.com/email/'

            let info = {
                name    : this.name.trim(),
                email   : this.email.trim(),
                msg     : this.msg.trim()
            }

            let data = new FormData()
            Object.keys(info).map(e => data.set(e, info[e]))

            let res = await axios({
                method: 'post',
                url,
                data,
                headers: {'Content-Type': 'multipart/form-data' }
            })
            if(res.data.pass) {
                this.resetForm()
                setTimeout(() => { this.runAlert(this.contact.alert.thanks, true) }, 50);
            }

            // After posting is over
            this.posting = false
        },
        resetForm() {
            this.name   = ''
            this.email  = ''
            this.msg    = ''
            this.$refs.name.removeAttribute("focused")
            this.$refs.email.removeAttribute("focused")
            this.$refs.msg.removeAttribute("focused")
            this.submitted = false
        },
        validate() {
            let name    = false,
                email   = false,
                msg     = false

            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line no-useless-escape
            let na = /^[a-zA-Z ]+$/

            email   = re.test(String(this.email).toLowerCase()) ? false : true
            name    = this.name.length < 2 || !na.test(String(this.name).toLowerCase())
            msg     = this.msg.length < 10

            this.error = {name, email, msg}
        },
        onSubmit() {
            if(this.posting) return
            this.runAlert(false)
            this.submitted = true
            this.validate()
            if(!this.hasError) {
                this.posting = true
                this.$refs.invisibleRecaptcha.execute()
                this.postingTimeout = setTimeout(() => {
                    this.posting = false;
                    this.runAlert(this.contact.alert.error)
                }, 4000);
            }
        },
        onVerify() {
            if(!this.hasError) {
                clearTimeout(this.postingTimeout)
                this.post()
            }
        },
        runAlert(txt, success=false) {
            if(txt === false) {
                this.showAlert = false
                return
            }

            this.alert.txt        = txt
            this.alert.success    = success
            this.showAlert        = true
        }
    },
    watch: {
        allInputs() {
            if(!this.submitted) this.runAlert(false)
            if(!this.submitted) return
            this.validate()
        }
    }
}
</script>